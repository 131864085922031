* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow: hidden;
  max-width: 450px;
  /* max-height: 950px; */
  position: relative;
  margin: 0 auto;
  font-family: Poppins;
  background-image: url("assets/images/backgrounds/logo.png");
}

#root {
  height: 100%;
  max-height: 950px;
  position: relative;
}

#modal {
  /* width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999; */
}

@media (min-width: 450px) {
  #root {
    /* min-height: 750px;
    height: 100vh;
    max-width: 450px; */
  }
}
